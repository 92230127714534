import {
  imagesGet,
  imagesMenuCategoriesGet,
  imagesCategoriesGet,
  imagesTagsGet,
  imageBrandsGet,
  imagePersonsGet,
  imageOrientationsGet,
  imagePresentationsGet,
  imageLocationsGet,
  imageAnglesGet,
  imageBackgroundsGet,
  imageFinishesGet
} from "./../../api/WordPress/Images/ImagesGet";
import { downloadsGet, downloadMenuCategoriesGet, downloadsTags } from "./../../api/WordPress/Downloads/DownloadsGet";
import { searchItemsGet } from "./../../api/WordPress/Search/SearchItemsGet";

import {
  transformDownload,
  transformImage,
  downloadListTransformer,
  imageListTransformer,
  searchTransformer
} from "./../../api/WordPress/Products/ProductsTransformer";
import { productCategoriesTransformer } from "../../api/WordPress/Images/ImagesTransformers";
import { downloadsMenuCategoriesTransformer } from "../../api/WordPress/Downloads/DownloadsMenuTransformer";

import { API_FETCHING, API_RECEIVED, API_FAILED } from "./ApiReducer";
import { ERROR_NOT_FOUND } from "../Error/ErrorReducer";
import dynamicSort from "../../ui/util/dynamicSort";

const oneResource = (type, method, transformer) => ({ slug }) => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type
  });

  try {
    const resource = await method({ slug: slug });

    if (resource.length === 0) {
      dispatch({ type: ERROR_NOT_FOUND });
    }

    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: transformer(resource[0])
    });
  } catch (e) {
    dispatch({
      type: API_FAILED,
      resourceType: type
    });
  }
};

const listResource = (type, method, transformer) => ({
  page,
  sort,
  order,
  category,
  productCategory,
  productTag,
  downloadTag,
  searchTerm,
  orientation,
  brand,
  person,
  background,
  finish,
  angle,
  presentation,
  location
}) => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type
  });

  try {
    const resources = await method({
      page: page,
      sort: sort,
      order: order,
      category: category,
      productCategory: productCategory,
      productTag: productTag,
      downloadTag: downloadTag,
      searchTerm: searchTerm,
      orientation: orientation,
      brand: brand,
      person: person,
      background: background,
      finish: finish,
      angle: angle,
      presentation: presentation,
      location: location
    });

    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: transformer(resources)
    });
  } catch (e) {
    if (e.code === "taxonomy_does_not_exist") {
      dispatch({ type: ERROR_NOT_FOUND });
    } else {
      dispatch({
        type: API_FAILED,
        resourceType: type
      });
    }
  }
};

const menuResource = (type, method, transformer) => () => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type
  });

  try {
    const resources = await method();
    if (resources.length === 0) {
      dispatch({ type: ERROR_NOT_FOUND });
    }
    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: transformer([...resources].sort(dynamicSort("order", 1)))
    });
  } catch (e) {
    dispatch({
      type: API_FAILED,
      resourceType: type
    });
  }
};

const searchResource = (type, method, transformer) => ({ page, searchTerm }) => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type
  });

  try {
    const resources = await method({
      page: page,
      searchTerm: searchTerm
    });
    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: transformer(resources)
    });
  } catch (e) {
    dispatch({
      type: API_FAILED,
      resourceType: type
    });
  }
};

const rawResource = (type, method) => () => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type
  });

  try {
    const resource = await method();

    if (resource.length === 0) {
      dispatch({ type: ERROR_NOT_FOUND });
    }

    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: resource
    });
  } catch (e) {
    dispatch({
      type: API_FAILED,
      resourceType: type
    });
  }
};

export const getProductMenuCategories = menuResource(
  "imageMenuCategories",
  imagesMenuCategoriesGet,
  productCategoriesTransformer
);
export const getImageCategories = menuResource("imageCategories", imagesCategoriesGet, productCategoriesTransformer);
export const getImageTags = menuResource("imageTags", imagesTagsGet, productCategoriesTransformer);
export const getImages = listResource("products", imagesGet, imageListTransformer);
export const getImage = oneResource("product", imagesGet, transformImage);

export const getDownloadMenuCategories = menuResource(
  "downloadsMenuCategories",
  downloadMenuCategoriesGet,
  downloadsMenuCategoriesTransformer
);
export const getDownloadTags = menuResource("downloadTags", downloadsTags, productCategoriesTransformer);
export const getDownloads = listResource("products", downloadsGet, downloadListTransformer);
export const getDownload = oneResource("product", downloadsGet, transformDownload);

export const getSearchItems = searchResource("search", searchItemsGet, searchTransformer);

export const getBrands = rawResource("brands", imageBrandsGet);
export const getImagePersons = rawResource("imagePersons", imagePersonsGet);
export const getImageOrientations = rawResource("imageOrientations", imageOrientationsGet);
export const getImageBackgrounds = rawResource("imageBackgrounds", imageBackgroundsGet);
export const getImageFinishes = rawResource("imageFinishes", imageFinishesGet);
export const getImageAngles = rawResource("imageAngles", imageAnglesGet);
export const getImagePresentations = rawResource("imagePresentations", imagePresentationsGet);
export const getImageLocations = rawResource("imageLocations", imageLocationsGet);
