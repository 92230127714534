import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" stroke="#232E7C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M1.47 1.47l15.06 15.06M16.53 1.47L1.47 16.53" />
    </g>
  </svg>
);
