export const API_FETCHING = "API_FETCHING";
export const API_RECEIVED = "API_RECEIVED";
export const API_FAILED = "API_FAILED";

const initialState = {
  api: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_FETCHING: {
      return {
        ...state,
        [action.resourceType]: null
      };
    }
    case API_RECEIVED: {
      return {
        ...state,
        [action.resourceType]: action.resource
      };
    }
    default: {
      return state;
    }
  }
};
