import React from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";

import { HOMEPAGE } from "./../../../routing";
import Page from "./../../layouts/Page/Page";
import Column from "./../../layouts/Column/Column";
import Row from "./../../layouts/Row/Row";
import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import Button from "./../../atoms/Button/Button";
import Arrow from "./../../svg/Arrow";

import "./NotFound.css";

const NotFound = () => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = 404;
      }

      return (
        <Page className="not-found" title="Page not found">
          <Row className="not-found">
            <Column className="not-found__image">
              <img alt="homepage-logo" className="homepage__logo" src="/images/not_found.png" />
            </Column>
            <Column className="not-found__text">
              <Typography className="not-found__title" tag="h1" variant={VARIANT.H1}>
                ¿No encuentras tu sitio?
              </Typography>
              <Typography className="not-found__subtitle" tag="h3" variant={VARIANT.H3}>
                A lo mejor te gustaría volver a casa
              </Typography>
              <Link to={formatRoute(HOMEPAGE)}>
                <Button className="not-found__button" secondary>
                  <Typography variant={VARIANT.H5}>Volver</Typography>
                  <Arrow className="not-found__svg" />
                </Button>
              </Link>
            </Column>
          </Row>
        </Page>
      );
    }}
  />
);

export default connect()(NotFound);
