import React from "react";

import Row from "./../../../layouts/Row/Row";
import Column from "./../../../layouts/Column/Column";
import Typography, { VARIANT } from "../../../atoms/Typography/Typography";
import ImageFile from "./ImageFile/ImageFile";
import { PRODUCTS_TITLE } from "./../../../../config/Constants";

import "./ProductDownloads.css";

const ProductDownloads = ({ product }) => (
  <Row className="product-downloads">
    <Column className="product-downloads__column">
      <div className="product-downloads__title">
        <Typography secondary tag="h3" variant={VARIANT.H3}>
          Descargas
        </Typography>
      </div>
      {product.mainTaxon.name === PRODUCTS_TITLE
        ? product.relatedImages.map((item, index) => <ImageFile item={item} key={index} type="product" />)
        : product.files.map((item, index) => <ImageFile item={item} key={index} type="download" />)}
    </Column>
  </Row>
);

export default React.memo(ProductDownloads);
