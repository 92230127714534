import React from "react";

import Resize from "./../context/ResizeContext";

const withSize = Component => props => (
  <Resize.Consumer>
    {({ breakpoint, windowWidth }) => <Component breakpoint={breakpoint} windowWidth={windowWidth} {...props} />}
  </Resize.Consumer>
);

export default withSize;
