import React from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import { formatRoute } from "react-router-named-routes";
import queryString from "query-string";

import { PRODUCTS_LIST, DOWNLOADS_LIST } from "./../../../routing";
import { PRODUCTS_TITLE } from "./../../../config/Constants";
import buildQueryParams from "./../../util/buildQueryParams";

import ProductRender from "./ProductRender";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      orderString: "Fecha (menor a mayor)",
      categories: [],
      tags: [],
      brands: [],
      orientations: [],
      persons: [],
      backgrounds: [],
      finishes: [],
      angles: [],
      presentations: [],
      locations: [],
      filterBy: [],
      selectedFilters: {
        categories: [],
        tags: [],
        brands: [],
        orientations: [],
        persons: [],
        backgrounds: [],
        finishes: [],
        angles: [],
        presentations: [],
        locations: []
      },
      filtering: false,
      sort: null,
      order: null
    };
    this.changeOption = this.changeOption.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.changeSelectedOption = this.changeSelectedOption.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const search = queryString.parse(location.search);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      categories: search.categories ? search.categories.split(", ").map(category => parseInt(category, 10)) : [],
      tags: search.tags ? search.tags.split(", ").map(tag => parseInt(tag, 10)) : [],
      brands: search.brands ? search.brands.split(", ").map(brand => parseInt(brand, 10)) : [],
      orientations: search.orientations
        ? search.orientations.split(", ").map(orientation => parseInt(orientation, 10))
        : [],
      persons: search.persons ? search.persons.split(", ").map(person => parseInt(person, 10)) : [],
      backgrounds: search.backgrounds ? search.backgrounds.split(", ").map(background => parseInt(background, 10)) : [],
      finishes: search.finishes ? search.finishes.split(", ").map(finishe => parseInt(finishe, 10)) : [],
      angles: search.angles ? search.angles.split(", ").map(angle => parseInt(angle, 10)) : [],
      presentations: search.presentations
        ? search.presentations.split(", ").map(presentation => parseInt(presentation, 10))
        : [],
      locations: search.locations ? search.locations.split(", ").map(_location => parseInt(_location, 10)) : [],
      filtering:
        search.tags ||
        search.categories ||
        search.brands ||
        search.orientations ||
        search.persons ||
        search.backgrounds ||
        search.finishes ||
        search.angles ||
        search.presentations
    });
  }

  changePage(mainTaxon, selected) {
    const { match } = this.props;
    const {
      categories,
      tags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations,
      order,
      sort
    } = this.state;
    const routeTo = mainTaxon === PRODUCTS_TITLE ? PRODUCTS_LIST : DOWNLOADS_LIST;
    const params = { page: selected || 1, category: match.params.category, slug: mainTaxon.slug };
    const queryParams = buildQueryParams({
      categories,
      tags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations,
      order,
      sort
    });
    this.props.history.push(`${formatRoute(routeTo, params)}?${queryString.stringify(queryParams)}`);
  }

  applyFilters(mainTaxon) {
    const { match } = this.props;
    const { selectedFilters, order, sort } = this.state;
    const categories = [],
      tags = [],
      filterBy = [],
      brands = [],
      orientations = [],
      persons = [],
      backgrounds = [],
      finishes = [],
      angles = [],
      presentations = [],
      locations = [];
    selectedFilters.categories.map(category => {
      categories.push(category.id);
      filterBy.push(category.name);
      return true;
    });
    selectedFilters.tags.map(tag => {
      tags.push(tag.id);
      filterBy.push(tag.name);
      return true;
    });
    selectedFilters.brands.map(brand => {
      brands.push(brand.id);
      filterBy.push(brand.name);
      return true;
    });
    selectedFilters.orientations.map(orientation => {
      orientations.push(orientation.slug);
      filterBy.push(orientation.name);
      return true;
    });
    selectedFilters.persons.map(person => {
      persons.push(person.slug);
      filterBy.push(person.name);
      return true;
    });
    selectedFilters.backgrounds.map(background => {
      backgrounds.push(background.id);
      filterBy.push(background.name);
      return true;
    });
    selectedFilters.finishes.map(finish => {
      finishes.push(finish.slug);
      filterBy.push(finish.name);
      return true;
    });
    selectedFilters.angles.map(angle => {
      angles.push(angle.slug);
      filterBy.push(angle.name);
      return true;
    });
    selectedFilters.presentations.map(presentation => {
      presentations.push(presentation.slug);
      filterBy.push(presentation.name);
      return true;
    });
    selectedFilters.locations.map(location => {
      locations.push(location.slug);
      filterBy.push(location.name);
      return true;
    });
    this.setState({
      categories: categories,
      tags: tags,
      brands: brands,
      orientations: orientations,
      persons: persons,
      backgrounds: backgrounds,
      finishes: finishes,
      angles: angles,
      presentations: presentations,
      locations: locations,
      filterBy: filterBy,
      filtering: true
    });
    const routeTo = mainTaxon === PRODUCTS_TITLE ? PRODUCTS_LIST : DOWNLOADS_LIST;
    const queryParams = buildQueryParams({
      categories,
      tags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations,
      order,
      sort
    });
    const params = { page: 1, category: match.params.category, slug: mainTaxon.slug };
    this.props.history.push(`${formatRoute(routeTo, params)}?${queryString.stringify(queryParams)}`);
  }

  changeOption(mainTaxon, option) {
    const { match } = this.props;
    const { order, sort, name } = option;
    const {
      categories,
      tags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations
    } = this.state;
    this.setState({ selected: null, orderString: name, order: order, sort: sort });
    const routeTo = mainTaxon === PRODUCTS_TITLE ? PRODUCTS_LIST : DOWNLOADS_LIST;
    const queryParams = buildQueryParams({
      categories,
      tags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations,
      order,
      sort
    });
    const params = { page: 1, category: match.params.category, slug: mainTaxon.slug };
    this.props.history.push(`${formatRoute(routeTo, params)}?${queryString.stringify(queryParams)}`);
  }

  changeFilters(type, option) {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [type]: this.state.selectedFilters[type]
          .filter(x => x !== option)
          .concat([option].filter(x => !this.state.selectedFilters[type].includes(x)))
      }
    });
  }

  resetFilter(mainTaxon) {
    const { match } = this.props;
    this.setState({
      categories: [],
      tags: [],
      orientations: [],
      persons: [],
      backgrounds: [],
      finishes: [],
      angles: [],
      presentations: [],
      locations: [],
      filterBy: [],
      filtering: false,
      selectedFilters: {
        categories: [],
        tags: [],
        brands: [],
        orientations: [],
        persons: [],
        backgrounds: [],
        finishes: [],
        angles: [],
        presentations: [],
        locations: []
      }
    });
    const routeTo = mainTaxon === PRODUCTS_TITLE ? PRODUCTS_LIST : DOWNLOADS_LIST;
    const params = { page: 1, category: match.params.category, slug: mainTaxon.slug };
    this.props.history.push(formatRoute(routeTo, params));
  }

  changeSelectedOption(selectedOption) {
    const { selected } = this.state;
    selected === selectedOption ? this.setState({ selected: null }) : this.setState({ selected: selectedOption });
  }

  render() {
    const {
      products,
      paging,
      match,
      history,
      downloadTags,
      imageCategories,
      imageTags,
      brands,
      orientations,
      persons,
      backgrounds,
      finishes,
      angles,
      presentations,
      locations
    } = this.props;
    const mainTaxon = get(products, "0.mainTaxon");
    const subTaxon = get(products, "0.subTaxon");
    const { selected, filterBy, filtering, selectedFilters, orderString } = this.state;
    const totalPages = get(paging, "totalPages") || 0;
    const totalItems = get(paging, "total") || 0;
    const currentPage = get(match, "params.page") || 1;
    return (
      <ProductRender
        angles={angles}
        applyFilters={() => this.applyFilters(mainTaxon.name)}
        backgrounds={backgrounds}
        brands={brands}
        changeFilters={(type, option) => this.changeFilters(type, option)}
        changeOption={option => this.changeOption(mainTaxon.name, option)}
        changePage={selectedPage => this.changePage(mainTaxon.name, selectedPage)}
        currentPage={currentPage}
        downloadTags={downloadTags}
        filterBy={filterBy}
        filtering={filtering}
        finishes={finishes}
        history={history}
        imageCategories={imageCategories}
        imageTags={imageTags}
        locations={locations}
        mainTaxon={mainTaxon}
        orderString={orderString}
        orientations={orientations}
        paging={paging}
        persons={persons}
        presentations={presentations}
        products={products}
        resetFilter={() => this.resetFilter(mainTaxon)}
        selected={selected}
        selectedFilters={selectedFilters}
        showOptions={selectedOption => this.changeSelectedOption(selectedOption)}
        subTaxon={subTaxon}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    );
  }
}

const mapStateToProps = state => ({
  products: get(state, "api.products.products"),
  paging: get(state, "api.products.paging"),
  location: state.router.location,
  imageCategories: get(state, "api.imageCategories"),
  imageTags: get(state, "api.imageTags"),
  downloadTags: get(state, "api.downloadTags"),
  brands: get(state, "api.brands"),
  orientations: get(state, "api.imageOrientations"),
  persons: get(state, "api.imagePersons"),
  backgrounds: get(state, "api.imageBackgrounds"),
  finishes: get(state, "api.imageFinishes"),
  angles: get(state, "api.imageAngles"),
  presentations: get(state, "api.imagePresentations"),
  locations: get(state, "api.imageLocations")
});

export default connect(mapStateToProps)(React.memo(Products));
