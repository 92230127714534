import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M19.49 3.106h2.34c.515 0 .936.422.936.937v13.106a.939.939 0 0 1-.936.936H2.17a.939.939 0 0 1-.936-.936V2.17c0-.515.421-.936.936-.936H9.13M12 3.106h2.809M9.129 1.234L12 3.106" />
      <path d="M19.49 9.66l-2.341-1.405-2.34 1.405V1.234h4.68z" />
    </g>
  </svg>
);
