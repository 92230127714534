import { LOCATION_CHANGE } from "connected-react-router";

export const ERROR_NOT_FOUND = "ERROR_NOT_FOUND";

export const ERROR_CODE_NOT_FOUND = 404;

const initialState = {
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_NOT_FOUND: {
      return {
        ...state,
        error: ERROR_CODE_NOT_FOUND
      };
    }
    case LOCATION_CHANGE: {
      return "key" in action.payload.location ? { ...state, error: null } : state;
    }
    default: {
      return state;
    }
  }
};
