import React from "react";
import PropTypes from "prop-types";

import Loader from "./../atoms/Loader/Loader";

const Loadable = ({ isLoaded, render, children }) => (isLoaded ? render ? render() : children : <Loader />);

Loadable.propTypes = {
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
  render: PropTypes.func
};

export default Loadable;
