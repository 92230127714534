import React from "react";
import classNames from "classnames";

import "./FormActions.css";

const FormActions = ({ children, className, isSubmitting }) => (
  <div className={classNames("form-actions", className)}>
    {isSubmitting ? <span className="form-actions__submitting">Enviando...</span> : children}
  </div>
);

export default FormActions;
