import React from "react";
import { formatRoute } from "react-router-named-routes";
import { Link } from "react-router-dom";
import get from "lodash.get";
import { connect } from "react-redux";
import classNames from "classnames";

import Typography, { VARIANT } from "./../../atoms/Typography/Typography";
import Close from "./../../svg/Close";
import ArrowDown from "./../../svg/ArrowDown";
import { PRODUCTS_LIST, DOWNLOADS_LIST } from "./../../../routing";

import "./MenuItem.css";

const getMenuItems = ({ name, imageMenu, historicMenu, logosMenu }) =>
  name === "Imágenes" ? imageMenu : name === "Histórico" ? historicMenu : logosMenu;

const MenuLink = ({ className, name, link, closeMenu, variant, ...rest }) => (
  <>
    <Link onClick={() => closeMenu(null)} to={link}>
      <div className={className}>
        <Typography variant={variant}>{name}</Typography>
        <ArrowDown className="menu-item__info-item-pike" />
      </div>
    </Link>
    {rest.children}
  </>
);

class MenuItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion(name) {
    this.setState({ isOpen: this.state.isOpen === name ? null : name });
  }

  render() {
    const { closeMenu, name, imageMenu, historicMenu, logosMenu, slug } = this.props;
    const { isOpen } = this.state;
    const menuItems = getMenuItems({ name, imageMenu, historicMenu, logosMenu });
    return (
      <div className="menu-item">
        <Close className="menu-item__close" onClick={() => closeMenu(null)} />
        <div className="menu-item__info">
          <Typography className="menu-item__title" variant={VARIANT.H5}>
            {name}
          </Typography>
          {menuItems &&
            menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.children.length < 1 ? (
                  <MenuLink
                    className="menu-item__info-item"
                    closeMenu={closeMenu}
                    link={
                      item.category === "product"
                        ? formatRoute(PRODUCTS_LIST, { category: item.slug })
                        : formatRoute(DOWNLOADS_LIST, { slug: item.group, category: item.slug })
                    }
                    name={item.name}
                    variant={VARIANT.BODY}
                  />
                ) : (
                  <div>
                    <div className="menu-item__info-multi-item" onClick={() => this.toggleAccordion(item.name)}>
                      <Typography>{item.name}</Typography>
                      <Close
                        className={classNames("menu-item__info-item-close", {
                          "menu-item__info-item-close--active": isOpen === item.name
                        })}
                      />
                    </div>
                    {isOpen === item.name && (
                      <div className="menu-item__info-item-children">
                        {item.children.map((child, index2) => (
                          <MenuLink
                            children={child.children}
                            className="menu-item__info-child"
                            closeMenu={closeMenu}
                            key={index2}
                            link={formatRoute(DOWNLOADS_LIST, { slug: slug, category: child.slug })}
                            name={child.name}
                            variant={VARIANT.SMALL_BODY}
                          >
                            {child.children &&
                              child.children.map((grandchild, index3) => (
                                <MenuLink
                                  className="menu-item__info-grandchild"
                                  closeMenu={closeMenu}
                                  key={index3}
                                  link={formatRoute(DOWNLOADS_LIST, { slug: slug, category: grandchild.slug })}
                                  name={grandchild.name}
                                  variant={VARIANT.SMALL_BODY}
                                />
                              ))}
                          </MenuLink>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imageMenu: state.api.imageMenuCategories,
  historicMenu: get(state, "api.downloadsMenuCategories.historic"),
  logosMenu: get(state, "api.downloadsMenuCategories.logos")
});

export default connect(mapStateToProps)(React.memo(MenuItem));
