import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withSize from "./../../../_packages/resize/ui/withSize";

import { valuePerBreakpointForConfig } from "./../../_config/breakpoints";
import prefixStyles from "./../../_util/prefixStyles";
import Row from "./../Row/Row";
import Column from "./../Column/Column";

const Grid = ({ children, className, columnsPerBreakpoint, itemClassName, windowWidth }) => {
  const columnsPerView = valuePerBreakpointForConfig(columnsPerBreakpoint)(windowWidth);

  return (
    <Row className={classNames("grid", className)}>
      {React.Children.map(children, (item, index) => (
        <Column
          className={classNames("grid__item", itemClassName)}
          key={index}
          style={prefixStyles({
            flexBasis: `${(1 / columnsPerView) * 100}%`,
            maxWidth: `${(1 / columnsPerView) * 100}%`
          })}
        >
          {item}
        </Column>
      ))}
    </Row>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnsPerBreakpoint: PropTypes.object,
  itemClassName: PropTypes.string
};

Grid.defaultProps = {
  columnsPerBreakpoint: { S: 1 }
};

export default React.memo(withSize(Grid));
