import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M15 10.43478261v4.26086959C15 14.8630435 14.8630435 15 14.6956522 15H1.30434783C1.13695653 15 1 14.8630435 1 14.6956522v-4.26086959M8 1v10.3478261" />
      <path d="M11.0434783 8L8 11.3478261 4.95652174 8" />
    </g>
  </svg>
);
