import createHistory from "history/createBrowserHistory";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { getCookie, USER_TOKEN_COOKIE } from "./../ui/_util/cookies";

const enhancers = [];
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

export const history = createHistory();

export default createStore(
  connectRouter(history)(rootReducer),
  window.__PRELOADED_STATE__ || {
    user: {
      token: getCookie(USER_TOKEN_COOKIE)
    }
  },
  compose(applyMiddleware(thunk, routerMiddleware(history)), ...enhancers)
);
