import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import Resize from "./../context/ResizeContext";

const isSSR = () => typeof window === "undefined";

const getBreakpoint = breakpoints => {
  const breakpointKeys = Object.keys(breakpoints)
    .sort((keyA, keyB) => (breakpoints[keyA] > breakpoints[keyB] ? 1 : -1))
    .reverse();

  return width => breakpointKeys.find(key => width >= breakpoints[key]);
};

class ResizeProvider extends React.Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);
    this.updateInnerFunctions = this.updateInnerFunctions.bind(this);

    this.updateInnerFunctions();

    const initialWindowWidth = isSSR() ? this.props.defaultWindowWidth : window.innerWidth;

    this.state = {
      breakpoint: this.getBreakpoint(initialWindowWidth),
      windowWidth: initialWindowWidth
    };
  }

  handleResize(time) {
    return debounce(() => {
      this.setState({
        breakpoint: this.getBreakpoint(window.innerWidth),
        windowWidth: window.innerWidth
      });
    }, time);
  }

  updateInnerFunctions() {
    this.getBreakpoint = getBreakpoint(this.props.breakpoints);
    this.debouncedResizeHandler = this.handleResize(this.props.debounce);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.breakpoints !== this.props.breakpoints || prevProps.debounce !== this.props.debounce) {
      this.updateInnerFunctions();
      this.debouncedResizeHandler();
    }
  }

  componentDidMount() {
    if (isSSR()) {
      return;
    }

    window.addEventListener("resize", this.debouncedResizeHandler);
  }

  componentWillUnmount() {
    if (isSSR()) {
      return;
    }

    window.removeEventListener("resize", this.debouncedResizeHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState.breakpoint !== this.state.breakpoint;
  }

  render() {
    return <Resize.Provider value={this.state}>{this.props.children}</Resize.Provider>;
  }
}

ResizeProvider.propTypes = {
  breakpoints: PropTypes.object,
  children: PropTypes.node.isRequired,
  debounce: PropTypes.number,
  defaultWindowWidth: PropTypes.number
};

ResizeProvider.defaultProps = {
  debounce: 50,
  defaultWindowWidth: 1024,
  breakpoints: {
    S: 0,
    M: 768,
    L: 1024,
    XL: 1280,
    XXL: 1440
  }
};

export default ResizeProvider;
