import React from "react";
import classNames from "classnames";

import Typography, { VARIANT } from "../../../atoms/Typography/Typography";

import "./FilterOptions.css";

const FilterOptions = ({ active, changeFilters, options, selected, type }) => (
  <div className={classNames("filter-options", { "filter-options--active": type === active })}>
    {options &&
      options
        .sort((a, b) => a.name > b.name)
        .map((option, index) => (
          <div className="filter-options__option" key={index}>
            <div className="filter-options__option-parent" onClick={() => changeFilters(option)}>
              <input
                checked={selected.includes(option)}
                className="filter-options__option-check"
                readOnly={true}
                type="checkbox"
              />
              <Typography variant={VARIANT.H5}>{option.name}</Typography>
            </div>

            {option.children &&
              option.children
                .sort((a, b) => a.name > b.name)
                .map((child, index2) => (
                  <div className="filter-options__sub-option" key={index2} onClick={() => changeFilters(child)}>
                    <input
                      checked={selected.includes(child)}
                      className="filter-options__option-check"
                      readOnly={true}
                      type="checkbox"
                    />
                    <Typography className="filter-options__sub-option--text">{child.name}</Typography>
                  </div>
                ))}
          </div>
        ))}
  </div>
);

export default FilterOptions;
