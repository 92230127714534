import React from "react";

import Row from "./../../../layouts/Row/Row";
import Column from "./../../../layouts/Column/Column";
import Typography, { VARIANT } from "./../../../atoms/Typography/Typography";
import formatDate from "./../../../util/formatDate";
import Square from "./../../../svg/Square";
import Images from "./../../../svg/Images";
import Historic from "./../../../svg/Historic";
import { PRODUCTS_TITLE } from "../../../../config/Constants";
import isSSR from "./../../../../config/isSSR";

import "./ProductHeader.css";

const defaultImage = "/images/aldi_new_logo.png";

const ProductAttributes = ({ product }) => (
  <div className="product-header__info-content">
    <div className="product-header__info-content-tags">
      <div className="product-header__info-content-tags-element">
        <Square />
        <Typography variant={VARIANT.H6}>Categoría: </Typography>
        <Typography>{product.categories.join(", ")} </Typography>
      </div>
      <div className="product-header__info-content-tags-element">
        <Square />
        <Typography variant={VARIANT.H6}>Tags: </Typography>
        <Typography>{product.tags.join(", ")} </Typography>
      </div>
      {product.mainTaxon.name === PRODUCTS_TITLE && (
        <div className="product-header__info-content-tags-element">
          <Square />
          <Typography variant={VARIANT.H6}>ID: </Typography>
          <Typography>{product.productId}</Typography>
        </div>
      )}
    </div>
    <div className="product-header__info-content-balloon">
      <div className="placeholder-circle" />
      {product.mainTaxon.name === PRODUCTS_TITLE ? (
        <Images className="product-header__info-svg" />
      ) : (
        <Historic className="product-header__info-svg" />
      )}
    </div>
  </div>
);

class ProductHeader extends React.Component {
  componentDidMount() {
    if (!isSSR() && window.objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  }

  render() {
    const { product } = this.props;
    return (
      <Row className="product-header">
        <Column className="product-header__column">
          <div className="product-header__image-wrapper">
            <img
              alt=""
              className="product-header__image"
              data-object-fit="cover"
              src={product.featuredImage || defaultImage}
            />
          </div>
          <div className="product-header__info">
            <div className="product-header__info-title">
              <Typography secondary tag="h4" variant={VARIANT.H4}>
                {product.title}
              </Typography>
              <Typography secondary tag="h4" variant={VARIANT.H5}>
                {formatDate(product.date)}
              </Typography>
            </div>
            <ProductAttributes product={product} />
          </div>
        </Column>
      </Row>
    );
  }
}

export default React.memo(ProductHeader);
