import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, isOpen, ...rest }) => (
  <svg className={className} viewBox="0 0 22 14" {...rest}>
    <g fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M1 7h20M14.3333333 1L21 7l-6.6666667 6" />
    </g>
  </svg>
);
