import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { withRouter } from "react-router";
import { Page as AldiPage } from "lin3s-react-analytics-enhanced-ecommerce";

import SideMenu from "./../../compositions/SideMenu/SideMenu";
import Header from "./../../compositions/Header/Header";
import { isUserLoggedIn } from "./../../../store/User/UserReducer";

import "./Page.css";

const defaultTitle = "Aldi Brand Center";
const defaultDescription = "Aldi Brand Center";
const defaultImage = `/images/logo.jpg`;
const defaultTwitter = "@aldi_es";
const defaultSep = " | ";
const defaultUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://www.lin3s.com";

class Page extends React.Component {
  getMetaTags(
    { title, description, image, contentType, twitter, noCrawl, published, updated, category, tags },
    pathname
  ) {
    const theTitle = title ? (title + defaultSep + defaultTitle).substring(0, 60) : defaultTitle;
    const theDescription = description ? description.substring(0, 155) : defaultDescription;
    const theImage = image ? image : defaultImage;

    const metaTags = [
      { itemprop: "name", content: theTitle },
      { itemprop: "description", content: theDescription },
      { itemprop: "image", content: theImage },
      { name: "description", content: theDescription },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: defaultTwitter },
      { name: "twitter:title", content: theTitle },
      { name: "twitter:description", content: theDescription },
      { name: "twitter:creator", content: twitter || defaultTwitter },
      { name: "twitter:image:src", content: theImage },
      { property: "og:title", content: theTitle },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: defaultUrl + pathname },
      { property: "og:image", content: theImage },
      { property: "og:description", content: theDescription },
      { property: "og:site_name", content: defaultTitle },
      { property: "fb:app_id", content: "xxx" }
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }
    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }

    return metaTags;
  }

  render() {
    const { children, doNotTrack, isUserLoggedIn: propsIsUserLoggedIn, ...rest } = this.props;

    return (
      <AldiPage doNotTrack={doNotTrack}>
        <React.Fragment>
          {propsIsUserLoggedIn && <SideMenu />}
          {propsIsUserLoggedIn && <Header history={rest.history} />}
          <Helmet
            htmlAttributes={{
              lang: "en",
              itemscope: undefined,
              itemtype: `http://schema.org/${rest.schema || "WebPage"}`
            }}
            link={[
              {
                rel: "canonical",
                href: defaultUrl + this.props.location.pathname
              }
            ]}
            meta={this.getMetaTags(rest, this.props.location.pathname)}
            title={rest.title ? rest.title + defaultSep + defaultTitle : defaultTitle}
          />
          <section className="page__section">{children}</section>
        </React.Fragment>
      </AldiPage>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedIn: isUserLoggedIn(state)
});

export default withRouter(connect(mapStateToProps)(React.memo(Page)));
