const buildQueryParams = ({
  categories,
  tags,
  brands,
  orientations,
  persons,
  backgrounds,
  finishes,
  angles,
  presentations,
  locations,
  order,
  sort
}) => {
  const queryParams = {};
  categories.length > 0 && (queryParams.categories = categories.join(", "));
  tags.length > 0 && (queryParams.tags = tags.join(", "));
  brands.length > 0 && (queryParams.brands = brands.join(", "));
  orientations.length > 0 && (queryParams.orientations = orientations.join(", "));
  persons.length > 0 && (queryParams.persons = persons.join(", "));
  backgrounds.length > 0 && (queryParams.backgrounds = backgrounds.join(", "));
  finishes.length > 0 && (queryParams.finishes = finishes.join(", "));
  angles.length > 0 && (queryParams.angles = angles.join(", "));
  presentations.length > 0 && (queryParams.presentations = presentations.join(", "));
  locations.length > 0 && (queryParams.locations = locations.join(", "));
  order && (queryParams.order = order);
  sort && (queryParams.sort = sort);
  return queryParams;
};

export default buildQueryParams;
