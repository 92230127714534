import React from "react";

import FormError from "../FormError/FormError";
import classNames from "classnames";

import "./FormGroupInput.css";

const FormGroupInput = ({ className, errors, id, label, name, ...rest }) => (
  <div className={classNames("form-group", { "form-group--has-errors": errors }, className)}>
    <input className="form-input" id={id} name={name ? name : id} required={true} {...rest} />
    {label && (
      <label className="form-label" htmlFor={id}>
        {label}
      </label>
    )}
    {errors ? <FormError className="form-group__errors">{errors}</FormError> : null}
  </div>
);

export default FormGroupInput;
