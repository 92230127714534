import React from "react";
import PropTypes from "prop-types";

import Typography, { VARIANT } from "./../../atoms/Typography/Typography";
import Row from "./../../layouts/Row/Row";
import Column from "./../../layouts/Column/Column";

import "./PageHeader.css";

const PageHeader = ({ subtitle, title }) => (
  <header className="page-header">
    <Row>
      <Column className="page-header__column">
        <Typography className="page-header__title" secondary tag="h1" variant={VARIANT.H1}>
          {title}
        </Typography>
        {subtitle && (
          <Typography className="page-header__subtitle" secondary tag="h2" variant={VARIANT.LIGHT_HEADING}>
            '{subtitle}'
          </Typography>
        )}
      </Column>
    </Row>
  </header>
);

PageHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default React.memo(PageHeader);
