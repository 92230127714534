import wpApi from "./../Client";

export const downloadsGet = ({ slug, perPage = 12, page = 1, sort, order, category, downloadTag }) =>
  wpApi
    .downloads()
    .slug(slug)
    .orderby(sort)
    .order(order)
    .perPage(perPage)
    .page(page)
    .param("filter[download_category]", category)
    .param("download_tag", downloadTag);

export const downloadMenuCategoriesGet = () => wpApi.downloadMenu().param("parent", 0);

export const downloadsTags = () => wpApi.downloadTags();
