import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M19.838 19.837c0 .512-.419.93-.93.93H2.162a.933.933 0 0 1-.93-.93V6.814c0-.512.418-.93.93-.93h16.744c.512 0 .93.418.93.93v13.023z" />
      <path d="M4.023 3.558V2.163c0-.512.419-.93.93-.93h16.745c.511 0 .93.418.93.93v13.953" />
      <path
        d="M1.233 16.705L6.814 11l5.116 5.581 2.79-2.79 5.055 5.767M16.116 9.838a1.163 1.163 0 1 1-2.325-.002 1.163 1.163 0 0 1 2.325.002z"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
