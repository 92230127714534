import React from "react";

import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import { SelectOrderOptions } from "../../../config/Constants";
import AccordionSelect from "../../components/AccordionSelect/AccordionSelect";
import OrderOptions from "../../components/AccordionSelect/OrderOptions/OrderOptions";

const AccordionOrderComponent = ({ orderString, changeOption, showOptions, active }) => (
  <React.Fragment>
    <Typography variant={VARIANT.H5}>ORDENAR POR</Typography>
    <AccordionSelect active={active} placeholder={orderString} showOptions={showOptions} type="order">
      <OrderOptions
        active={active}
        changeOption={option => changeOption(option)}
        options={SelectOrderOptions}
        type="order"
      />
    </AccordionSelect>
  </React.Fragment>
);

export default AccordionOrderComponent;
