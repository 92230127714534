import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { renderRoutes } from "react-router-config";

import { login } from "./../../../store/User/UserActions";
import { isUserLoggedIn } from "./../../../store/User/UserReducer";
import Page from "./../../layouts/Page/Page";
import Column from "./../../layouts/Column/Column";
import Row from "./../../layouts/Row/Row";
import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import LoginForm from "./LoginForm/LoginForm";

import "./Login.css";

const Login = ({ isUserLoggedIn: propsIsUserLoggedIn, login: propsLogin, loginError, loginFetching, route }) => {
  if (propsIsUserLoggedIn) {
    return renderRoutes(route.routes);
  }

  return (
    <Page title="Login">
      <Row className="login">
        <Column className="login__image">
          <img alt="" className="login__logo" src="/images/login-background.png" />
        </Column>
        <Column>
          <Typography tag="h1" variant={VARIANT.H1}>
            ALDI Brand Center
          </Typography>

          <Typography className="login__subtitle" tag="h3" variant={VARIANT.H3}>
            Bienvenido, por favor accede a tu cuenta.
          </Typography>

          <LoginForm
            handleSubmit={credentials => propsLogin(credentials)}
            loginError={loginError}
            loginFetching={loginFetching}
          />
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = state => ({
  isUserLoggedIn: isUserLoggedIn(state),
  loginError: state.user.error,
  loginFetching: state.user.isFetching
});

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
