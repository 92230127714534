import cookie from "react-cookies";

const USER_TOKEN_COOKIE = "user-token";

const setCookie = ({ name, value = "", expiration, domain, path = "/" } = {}) => {
  let expires;

  if (expiration) {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() + expiration);

    expires = cookieDate;
  }

  cookie.save(name, value, {
    path,
    expires,
    domain
  });
};

const getCookie = name => cookie.load(name);

const removeCookie = (name, { domain, path = "/" } = {}) => cookie.remove(name, { domain, path });

export { USER_TOKEN_COOKIE, setCookie, getCookie, removeCookie };
