import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Typography.css";

const VARIANT = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  LIGHT_HEADING: "light-heading",
  BODY: "body",
  SMALL_BODY: "small-body",
  MENU: "menu"
};

const Typography = ({ className, tag: TypographyTag, children, secondary, uppercase, variant, ...rest }) => (
  <TypographyTag
    className={classNames(
      "typography",
      `typography--${variant}`,
      { "typography--secondary": secondary },
      { "typography--uppercase": uppercase },
      className
    )}
    {...rest}
  >
    {children}
  </TypographyTag>
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tag: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANT))
};

Typography.defaultProps = {
  tag: "span",
  variant: "body"
};

export { VARIANT };

export default React.memo(Typography);
