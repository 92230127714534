import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Row.css";

const Row = ({ children, className, ...rest }) => (
  <div className={classNames("row", className)} {...rest}>
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default React.memo(Row);
