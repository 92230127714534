import React from "react";

import { IMAGE_SHOW, BRANDING_SHOW, HISTORIC_SHOW } from "./../../../routing";
import Loadable from "./../../behaviours/Loadable";
import Grid from "./../../layouts/Grid/Grid";
import Page from "./../../layouts/Page/Page";
import Row from "./../../layouts/Row/Row";
import MainAndAside from "./../../layouts/MainAndAside/MainAndAside";
import PageHeader from "./../../components/PageHeader/PageHeader";
import Paginator from "./../../components/Paginator/Paginator";
import Typography, { VARIANT } from "./../../atoms/Typography/Typography";
import Button from "./../../atoms/Button/Button";
import ProductPreview from "../../components/ProductPreview/ProductPreview";
import AccordionOrderComponent from "./../../compositions/AccordionOrderComponent/AccordionOrderComponent";
import AccordionFilterComponent from "./../../compositions/AccordionFilterComponent/AccordionFilterComponent";
import Arrow from "./../../svg/Arrow";
import { PRODUCTS_TITLE, DOWNLOADS_GROUPS } from "./../../../config/Constants";

import "./Products.css";

const ProductRender = ({
  applyFilters,
  brands,
  changeFilters,
  changeOption,
  changePage,
  currentPage,
  filterBy,
  filtering,
  history,
  mainTaxon,
  orderString,
  products,
  resetFilter,
  selected,
  selectedFilters,
  showOptions,
  subTaxon,
  totalItems,
  totalPages,
  downloadTags,
  imageCategories,
  imageTags,
  orientations,
  persons,
  backgrounds,
  finishes,
  angles,
  presentations,
  locations
}) => (
  <Page doNotTrack={!products} title="Products">
    <PageHeader title={mainTaxon && subTaxon ? `${mainTaxon.name} ${subTaxon.name}` : ""} />
    <Loadable
      isLoaded={Boolean(products)}
      render={() => (
        <React.Fragment>
          {products.length > 0 ? (
            <MainAndAside className="products__wrapper">
              <div>
                {filtering && (
                  <div className="products__filter-actions">
                    <Typography variant={VARIANT.H5}>ESTÁS FILTRANDO POR: {filterBy.join(", ")}</Typography>
                    <Typography
                      className="products__filter-actions-reset"
                      onClick={() => resetFilter()}
                      variant={VARIANT.H5}
                    >
                      LIMPIAR FILTRO
                    </Typography>
                  </div>
                )}
                <Grid
                  className="products__grid"
                  columnsPerBreakpoint={{ S: 1, SM: 2, XXL: 3 }}
                  itemClassName="products__grid-item"
                >
                  {products.map((product, index) => (
                    <ProductPreview
                      key={index}
                      product={product}
                      to={
                        /* eslint-disable indent */
                        mainTaxon.name === PRODUCTS_TITLE
                          ? IMAGE_SHOW
                          : mainTaxon.slug === DOWNLOADS_GROUPS.historical
                          ? HISTORIC_SHOW
                          : BRANDING_SHOW
                      }
                      /* eslint-enable indent */
                    />
                  ))}
                </Grid>
                <div className="products__filter-total">
                  <Typography variant={VARIANT.H5}>Total de productos: {totalItems}</Typography>
                </div>
                <Row className="products__pagination">
                  <Paginator
                    changePage={newPage => changePage(newPage)}
                    currentPage={parseInt(currentPage, 10)}
                    totalPages={parseInt(totalPages, 10)}
                  />
                </Row>
              </div>
              <div>
                <div className="products__filters">
                  <AccordionOrderComponent
                    active={selected}
                    changeOption={option => changeOption(option)}
                    mainTaxon={mainTaxon}
                    orderString={orderString}
                    showOptions={() => showOptions("order")}
                  />
                  <AccordionFilterComponent
                    active={selected}
                    angles={angles}
                    applyFilters={applyFilters}
                    backgrounds={backgrounds}
                    brands={brands}
                    changeFilters={(type, option) => changeFilters(type, option)}
                    downloadTags={downloadTags}
                    finishes={finishes}
                    imageCategories={imageCategories}
                    imageTags={imageTags}
                    locations={locations}
                    mainTaxon={mainTaxon.name}
                    orientations={orientations}
                    persons={persons}
                    presentations={presentations}
                    selectedFilters={selectedFilters}
                    showOptions={type => showOptions(type)}
                  />
                </div>
              </div>
            </MainAndAside>
          ) : (
            <div className="products__no-results">
              <Typography className="products__no-results-text" variant={VARIANT.H5}>
                Esta categoría no contiene ningún artículo
              </Typography>
              <Button className="products__button" onClick={() => history.goBack()} secondary>
                <Typography variant={VARIANT.H6}>VOLVER</Typography>
                <Arrow className="products__svg" />
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    />
  </Page>
);

export default ProductRender;
