import { getImage, getImages, getDownloads, getDownload, getSearchItems } from "./../store/Api/ApiActions";

import { getCookie, USER_TOKEN_COOKIE } from "./../ui/_util/cookies";
import Error from "./../ui/layouts/Error/Error";
import Homepage from "./../ui/templates/Homepage/Homepage";
import Login from "./../ui/templates/Login/Login";
import NotFound from "./../ui/templates/NotFound/NotFound";
import Product from "./../ui/templates/Product/Product";
import Products from "./../ui/templates/Products/Products";
import Search from "./../ui/templates/Search/Search";

import { PRODUCTS_SLUG } from "./../config/Constants";

export const HOMEPAGE = "/";
export const PRODUCTS_LIST = `/${PRODUCTS_SLUG.plural}/:category/:page?`;
export const HISTORIC_SHOW = "/historico/:subcategory/:slug";
export const BRANDING_SHOW = "/branding/:subcategory/:slug";
export const DOWNLOADS_LIST = "/:slug/:category/:page?";
export const IMAGE_SHOW = `/${PRODUCTS_SLUG.singular}/:subcategory/:slug`;
export const SEARCH_RESULTS = "/buscar/:searchTerm/:page?";

const isUserLoggedIn = () => !!getCookie(USER_TOKEN_COOKIE);

export default [
  {
    path: "/",
    component: Error,
    routes: [
      {
        path: "*",
        component: Login,
        exact: true,
        routes: [
          {
            path: HOMEPAGE,
            component: Homepage,
            exact: true
          },
          {
            path: IMAGE_SHOW,
            component: Product,
            exact: true,
            loadData: match => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(getImage({ slug: match.params.slug }));
              }
            }
          },
          {
            path: PRODUCTS_LIST,
            component: Products,
            exact: true,
            loadData: (match, { search }) => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(
                  getImages({
                    category: match.params.category,
                    page: match.params.page,
                    productCategory: search.categories,
                    productTag: search.tags,
                    order: search.order,
                    sort: search.sort,
                    orientation: search.orientations,
                    person: search.persons,
                    background: search.backgrounds,
                    finish: search.finishes,
                    angle: search.angles,
                    presentation: search.presentations,
                    location: search.locations,
                    brand: search.brands
                  })
                );
              }
            }
          },
          {
            path: SEARCH_RESULTS,
            component: Search,
            exact: true,
            loadData: match => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(getSearchItems({ searchTerm: match.params.searchTerm, page: match.params.page }));
              }
            }
          },
          // These two always in the end and in that particular order (HISTORIC_SHOW -> HISTORIC_SHOW -> DOWNLOADS_LIST)
          {
            path: HISTORIC_SHOW,
            component: Product,
            exact: true,
            loadData: match => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(getDownload({ slug: match.params.slug }));
              }
            }
          },
          {
            path: BRANDING_SHOW,
            component: Product,
            exact: true,
            loadData: match => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(getDownload({ slug: match.params.slug }));
              }
            }
          },
          {
            path: DOWNLOADS_LIST,
            component: Products,
            exact: true,
            loadData: (match, { search }) => async dispatch => {
              if (isUserLoggedIn()) {
                await dispatch(
                  getDownloads({
                    category: match.params.category,
                    page: match.params.page,
                    downloadTag: search.tags,
                    order: search.order,
                    sort: search.sort
                  })
                );
              }
            }
          },
          {
            path: "*",
            component: NotFound
          }
        ]
      }
    ]
  }
];
