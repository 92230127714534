export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

const initialState = {
  isAuthenticated: false,
  isFetching: false,
  error: false,
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: true,
        error: false,
        token: null
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        error: false,
        token: action.token
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        error: true,
        token: null
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        error: false,
        token: null
      };

    default:
      return state;
  }
};

export const isUserLoggedIn = state => !!state.user.token;
