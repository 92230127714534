import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatRoute } from "react-router-named-routes";

import { logout } from "./../../../store/User/UserActions";
import { getSearchItems } from "../../../store/Api/ApiActions";
import Row from "./../../layouts/Row/Row";
import Typography, { VARIANT } from "./../../atoms/Typography/Typography";
import Column from "./../../layouts/Column/Column";
import Logout from "./../../svg/Logout";
import Search from "./../../svg/Search";

import { SEARCH_RESULTS } from "./../../../routing";
import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: null
    };
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  search(e) {
    e.preventDefault();
    const { searchTerm } = this.state;
    this.props.history.push(formatRoute(SEARCH_RESULTS, { searchTerm: searchTerm }));
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ searchTerm: e.currentTarget.value });
  }

  render() {
    const propsLogout = this.props.logout;
    return (
      <header className="header">
        <Row className="header__row">
          <Column className="header__actions">
            <div className="header__actions-title">
              <Typography tag="h2" variant={VARIANT.H2}>
                Aldi Brand Center
              </Typography>
            </div>
            <form className="header__actions-search" onSubmit={this.search}>
              <input
                className="header__actions-search-input"
                name="searchterm"
                onChange={this.handleChange}
                placeholder="Buscar"
                type="text"
              />
              <Search className="header__actions-search-svg" onClick={this.search} />
            </form>
            <div className="header__actions-logout">
              <Logout className="header__actions-logout-svg" onClick={propsLogout} />
              <Typography className="header__actions-logout-text" onClick={propsLogout} variant={VARIANT.H5}>
                Cerrar sesión
              </Typography>
            </div>
          </Column>
        </Row>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  getSearchItemsAction: bindActionCreators(getSearchItems, dispatch)
});

export default connect(null, mapDispatchToProps)(React.memo(Header));
