import React from "react";
import { withFormik } from "formik";

import Button from "./../../../atoms/Button/Button";

import FormActions from "./../FormActions/FormActions";
import FormError from "./../FormError/FormError";
import FormGroupInput from "./../FormGroupInput/FormGroupInput";
import Typography, { VARIANT } from "../../../atoms/Typography/Typography";
import FormRow from "./../FormRow/FormRow";
import Arrow from "./../../../svg/Arrow";

import "./LoginForm.css";

const LoginForm = ({ errors, handleChange, handleSubmit, loginError, loginFetching, touched, values }) => (
  <form className="login-form" onSubmit={handleSubmit}>
    <FormRow>
      <FormGroupInput
        errors={touched._username && errors._username ? errors._username : null}
        id="_username"
        label="Usuario"
        onChange={handleChange}
        value={values._username}
      />
    </FormRow>
    <FormRow className="login-form__password">
      <FormGroupInput
        errors={touched._password && errors._password ? errors._password : null}
        id="_password"
        label="Contraseña"
        onChange={handleChange}
        type="password"
        value={values._password}
      />
    </FormRow>
    {loginError && (
      <FormRow>
        <FormError>Usuario y/o contraseña inválidos</FormError>
      </FormRow>
    )}
    <FormRow className="login-form__recover">
      <Typography variant={VARIANT.H6}>Olvidé mi contraseña</Typography>
    </FormRow>
    <FormActions isSubmitting={loginFetching}>
      <Button className="login-form__submit" secondary type="submit">
        <Typography variant={VARIANT.H5}>ENTRAR</Typography>
        <Arrow className="login-form__svg" />
      </Button>
    </FormActions>
  </form>
);

export default withFormik({
  displayName: "LoginForm",
  handleSubmit: (values, formikBag) => {
    if (typeof formikBag.props.handleSubmit !== "function") {
      return;
    }
    formikBag.props.handleSubmit(values);
  },
  mapPropsToValues: () => ({ _username: "", _password: "" }),
  validate: values => {
    const errors = {};

    if (!values._username) {
      errors._username = "Este campo es obligatorio";
    }

    if (!values._password) {
      errors._password = "Este campo es obligatorio";
    }

    return errors;
  }
})(LoginForm);
