import React from "react";
import { Link } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import PropTypes from "prop-types";
import get from "lodash.get";

import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import isSSR from "./../../../config/isSSR";

import "./ProductPreview.css";

const defaultImage = "/images/aldi_new_logo.png";

class ProductPreview extends React.Component {
  componentDidMount() {
    if (!isSSR() && window.objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  }

  render() {
    const { product, to } = this.props;
    return (
      <Link
        className="product-preview"
        to={formatRoute(to, {
          slug: product.slug,
          subcategory: product.subTaxon.slug,
          category: get(product, "mainTaxon.slug")
        })}
      >
        <div className="product-preview__image">
          <img alt="" data-object-fit="cover" src={product.featuredImage || defaultImage} />
        </div>
        <div className="product-preview__content">
          <Typography tag="h5" variant={VARIANT.H5}>
            {product.title}
          </Typography>
        </div>
      </Link>
    );
  }
}

ProductPreview.propTypes = {
  product: PropTypes.object,
  to: PropTypes.string.isRequired
};

export default React.memo(ProductPreview);
