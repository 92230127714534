import { combineReducers } from "redux";

import errorReducer from "./Error/ErrorReducer";
import apiReducer from "./Api/ApiReducer";
import userReducer from "./User/UserReducer";

export default combineReducers({
  error: errorReducer,
  user: userReducer,
  api: apiReducer
});
