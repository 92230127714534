import React from "react";
import classNames from "classnames";

import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import ArrowDown from "../../svg/ArrowDown";

import "./AccordionSelect.css";

const AccordionSelect = ({ active, placeholder, showOptions, children, type }) => (
  <div className="accordion-select">
    <div
      className={classNames("accordion-select__wrapper", { "accordion-select__wrapper--active": active === type })}
      onClick={showOptions}
    >
      <Typography variant={VARIANT.H5}>{placeholder}</Typography>
    </div>
    <ArrowDown className="accordion-select__svg" onClick={active === type ? showOptions : undefined} />
    {children}
  </div>
);

export default React.memo(AccordionSelect);
