import wpLogin from "./../../api/WordPress/User/Login";
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "./UserReducer";
import { removeCookie, setCookie, USER_TOKEN_COOKIE } from "./../../ui/_util/cookies";

export const login = data => async dispatch => {
  dispatch({
    type: LOGIN_REQUEST
  });

  try {
    const user = await wpLogin(data);

    const params = {
      name: USER_TOKEN_COOKIE,
      value: user.token,
      expiration: 2592000000 // 1 month
    };

    setCookie(params);

    dispatch({
      type: LOGIN_SUCCESS,
      token: user.token
    });

    window && window.location.assign("/");
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE
    });
  }
};

export const logout = () => async dispatch => {
  removeCookie(USER_TOKEN_COOKIE);

  dispatch({
    type: LOGOUT
  });
};
