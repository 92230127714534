import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" stroke="#424242" strokeLinecap="round" strokeLinejoin="round">
      <path d="M13.577 2.815a5.374 5.374 0 0 1 7.613 0 5.417 5.417 0 0 1 0 7.639M13.577 2.815L12 4.49M10.424 2.815a5.374 5.374 0 0 0-7.613 0 5.414 5.414 0 0 0 0 7.639M10.424 2.815L12 4.49" />
      <path d="M2.811 10.454l8.229 9.062a1.266 1.266 0 0 0 1.921 0l8.23-9.062" />
    </g>
  </svg>
);
