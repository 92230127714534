import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 32 35" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <defs>
      <path d="M0 .05881081h31.0753927V27.5308108H0z" id="a" />
      <path d="M0 .55747059h12.4425294V12.9998471H0z" id="c" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.9136432 25.2288l-4.6365405 2.5288649c-.27156756.1487567-.51632432.3303783-.74378378.5292973 2.7027027 2.2797837 6.19243248 3.654054 10.00475678 3.654054 3.7846486 0 7.2518919-1.3543784 9.9468108-3.6021621-.2482162-.2101622-.5180541-.3987027-.8172973-.5474595l-4.9643243-2.4821622c-.6417298-.3208648-1.0464865-.9764324-1.0464865-1.6934054v-1.9476756c.1392432-.1591352.2983784-.3632433.4696216-.6045406.6763243-.9556757 1.1883243-2.0064865 1.5429189-3.110054.6365406-.1963244 1.1061622-.7844325 1.1061622-1.4832433v-2.0791351c0-.4575135-.2041081-.8657298-.5197838-1.152V10.233773s.6175135-4.67718922-5.7167568-4.67718922c-6.33427023 0-5.71675672 4.67718922-5.71675672 4.67718922v3.0054054c-.31654054.2862702-.51978378.6944865-.51978378 1.152v2.0791351c0 .5474595.288 1.0300541.7187027 1.3085406.5197838 2.2616216 1.8802162 3.8892973 1.8802162 3.8892973v1.8992432c-.0008648.6918919-.3796757 1.3292973-.9876757 1.6614054"
        fill="#E3E4EB"
      />
      <g transform="translate(0 .806227)">
        <mask fill="#fff" id="b">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M15.8038486.06097297C7.22352432-.08518919.14892973 6.7507027.0019027 15.331027c-.08302702 4.8648649 2.08518919 9.2367568 5.53772973 12.1452973.22572973-.1971892.46789189-.3779459.73686487-.5241081l4.6365405-2.5288648c.608-.3321082.9868108-.9695136.9868108-1.6622703V20.860973s-1.3604324-1.6276757-1.8793513-3.8892973c-.43070271-.2784865-.71956757-.7593514-.71956757-1.3076757v-2.0791351c0-.4575135.20410811-.8665946.51978378-1.1528649V9.42659459S9.2032 4.74940541 15.5374703 4.74940541c6.3342702 0 5.7167567 4.67718918 5.7167567 4.67718918V12.432c.3165406.2862703.5197838.6953514.5197838 1.1528649V15.664c0 .6988108-.4687567 1.2869189-1.1061622 1.4832432-.3545945 1.1027027-.8665945 2.1543784-1.5429189 3.1100541-.1703783.2412973-.3303783.4445405-.4696216.6036757v1.9476756c0 .7178379.4047568 1.3734055 1.0464865 1.6934055l4.9643243 2.4821621c.2983784.1487568.5673514.3372973.8155676.5465946 3.3478919-2.7917838 5.5109189-6.9673513 5.5913513-11.6678919C31.2209297 7.28259459 24.384173.20713513 15.8038486.06097297"
          fill="#232E7C"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(19 21.207388)">
        <mask fill="#fff" id="d">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M6.22134118.55731765c3.43582353 0 6.22164702 2.78505882 6.22164702 6.22164706 0 3.43582349-2.78582349 6.22088239-6.22164702 6.22088239S-.00030588 10.2147882-.00030588 6.77896471c0-3.43658824 2.78582353-6.22164706 6.22164706-6.22164706"
          fill="#3ACC6C"
          mask="url(#d)"
        />
      </g>
      <path
        d="M28.62802941 24.96821176c-.23552941-.16364705-.55823529-.10552941-.72188235.1292353l-2.90129412 4.16841176-2.04711765-1.68082353c-.221-.182-.54829411-.14911764-.72952941.07264706-.182.221-.14988235.54752941.07188236.72952941l2.48147058 2.03641177c.09329412.07647059.20952942.11776471.32882353.11776471.02294118 0 .04741177-.00152942.07111765-.00458824.143-.01988235.27147059-.09864706.35482353-.21717647l3.22094118-4.62952941c.16364705-.23476471.10552941-.5582353-.1292353-.72188236"
        fill="#FFF"
      />
    </g>
  </svg>
);
