import React from "react";
import ReactDOM from "react-dom";

import routes from "./routing/index";
import store, { history } from "./store/store";
import {
  getProductMenuCategories,
  getDownloadMenuCategories,
  getImageCategories,
  getImageTags,
  getDownloadTags,
  getBrands,
  getImagePersons,
  getImageAngles,
  getImageBackgrounds,
  getImageFinishes,
  getImageLocations,
  getImageOrientations,
  getImagePresentations
} from "./store/Api/ApiActions";
import App from "./App";

import cssVars from "css-vars-ponyfill";
import "babel-polyfill";
import "objectFitPolyfill";

import "./ui/theme/Theme";

cssVars();

if (process.env.NODE_ENV === "development") {
  store.dispatch(getProductMenuCategories());
  store.dispatch(getDownloadMenuCategories());
  store.dispatch(getImageCategories());
  store.dispatch(getImageTags());
  store.dispatch(getDownloadTags());
  store.dispatch(getBrands());
  store.dispatch(getImageAngles());
  store.dispatch(getImagePersons());
  store.dispatch(getImageFinishes());
  store.dispatch(getImageLocations());
  store.dispatch(getImageBackgrounds());
  store.dispatch(getImageOrientations());
  store.dispatch(getImagePresentations());
}

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(<App history={history} routes={routes} store={store} />, document.getElementById("root"));
