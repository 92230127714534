import get from "lodash.get";

import {
  PRODUCTS_TITLE,
  PRODUCTS_SLUG,
  DOWNLOADS_TITLE,
  DOWNLOADS_GROUPS,
  ORIENTATION,
  FINISH,
  PRESENTATION,
  ANGLE,
  PERSON,
  LOCATION
} from "../../../config/Constants";

const transformPresentation = presentation =>
  Array.isArray(presentation) ? presentation.map(item => PRESENTATION[item]).join(", ") : "";

const transformProductIds = productIds =>
  Array.isArray(productIds) ? joinArray(productIds.map(productId => productId.product_id)) : "";

const joinArray = array => (Array.isArray(array) ? array.join(", ") : "");

const transformRelatedImages = images =>
  images.map(image => ({
    measures:
      get(image, "file.width") && get(image, "file.height")
        ? `${get(image, "file.width")}x${get(image, "file.height")}`
        : "",
    size:
      Math.round(get(image, "file.filesize") / 1048576).toFixed(2) > 0.01
        ? `${String(Math.round(get(image, "file.filesize") / 1048576))}MB`
        : `${String(Math.round(get(image, "file.filesize") / 1024))}KB`,
    file: get(image, "file.subtype"),
    image: get(image, "file.sizes.large"),
    thumbnail: get(image, "thumbnail.sizes.thumbnail") || get(image, "file.sizes.thumbnail"),
    title: get(image, "title"),
    type: get(image, "type"),
    description: get(image, "description", "").replace(/\r\n/gi, "<br>"),
    orientation: ORIENTATION[get(image, "orientation")],
    finish: FINISH[get(image, "finish")],
    presentation: transformPresentation(get(image, "presentation")),
    angle: ANGLE[get(image, "angle")],
    person: PERSON[get(image, "natural_person")],
    effectiveDate: get(image, "rights_validity"),
    support: get(image, "rights_support"),
    name: get(image, "file.name"),
    filename: get(image, "file.filename"),
    url: get(image, "file.url"),
    mediaId: get(image, "file.id"),
    location: LOCATION[get(image, "location")],
    brand: joinArray(get(image, "brand")),
    background: get(image, "background.name")
  }));

export const transformDownload = product => ({
  date: get(product, "date"),
  files: transformRelatedImages(get(product, "acf.files") || []),
  mainTaxon: {
    name: DOWNLOADS_TITLE[get(product, "download_category_list[0].group")],
    slug: DOWNLOADS_GROUPS[get(product, "download_category_list[0].group")]
  },
  subTaxon: {
    name: get(product, "download_category_list[0].name"),
    slug: get(product, "download_category_list[0].slug")
  },
  slug: get(product, "slug"),
  title: get(product, "title.rendered"),
  featuredImage: get(product, "better_featured_image.source_url"),
  categories: product.download_category_list.map(category => category.name),
  tags: product.download_tags_list.map(tag => tag.name)
});

export const transformImage = product => ({
  slug: get(product, "slug"),
  title: get(product, "title.rendered"),
  images: {
    thumbnail: get(product, "acf.images[0].file.sizes.thumbnail"),
    medium: get(product, "acf.images[0].file.sizes.medium"),
    large: get(product, "acf.images[0].file.sizes.large"),
    original: get(product, "acf.images[0].file.link")
  },
  productId: transformProductIds(get(product, "acf.product_ids")),
  relatedImages: transformRelatedImages(get(product, "acf.images") || []),
  featuredImage: get(product, "better_featured_image.source_url"),
  date: get(product, "date"),
  mainTaxon: {
    name: PRODUCTS_TITLE,
    slug: PRODUCTS_SLUG.plural
  },
  subTaxon: {
    name: get(product, "product_menu_list[0].name"),
    slug: get(product, "product_menu_list[0].slug")
  },
  categories: product.product_category_list.map(category => category.name),
  tags: product.product_tags_list.map(tag => tag.name)
});

export const downloadListTransformer = response => ({
  products: response.map(transformDownload),
  paging: response._paging
});

export const imageListTransformer = response => ({
  products: response.map(transformImage),
  paging: response._paging
});

export const transformSearchItem = item => ({
  id: item.id,
  slug: item.slug,
  productId: transformProductIds(get(item, "product_ids")),
  type: item.type,
  featuredImage: item.image,
  title: item.title,
  mainTaxon: item.type === "product" ? PRODUCTS_TITLE : DOWNLOADS_TITLE[get(item, "download_category[0].group")],
  subTaxon: {
    name: item.type === "product" ? get(item, "product_menu[0].name") : get(item, "download_category[0].name"),
    slug: item.type === "product" ? get(item, "product_menu[0].slug") : get(item, "download_category[0].slug")
  }
});

export const searchTransformer = response => ({
  search: response.map(transformSearchItem),
  paging: response._paging
});
