import wpApi from "./../Client";

export const imagesGet = ({
  slug,
  perPage = 12,
  page = 1,
  sort,
  order,
  category,
  productCategory,
  productTag,
  orientation,
  person,
  background,
  finish,
  angle,
  presentation,
  location,
  brand
}) =>
  wpApi
    .imageSearch()
    .slug(slug)
    .orderby(sort)
    .order(order)
    .perPage(perPage)
    .page(page)
    .param("filter[product_menu]", category)
    .param("product_category", productCategory)
    .param("product_tag", productTag)
    .param("orientation", orientation)
    .param("natural_person", person)
    .param("background", background)
    .param("finish", finish)
    .param("angle", angle)
    .param("presentation", presentation)
    .param("location", location)
    .param("brand", brand);

export const imagesCategoriesGet = () => wpApi.productCategories().param("parent", 0);

export const imagesTagsGet = () => wpApi.productTags();

export const imagesMenuCategoriesGet = () => wpApi.productMenu();

export const imageBrandsGet = () => wpApi.brands();
export const imageBackgroundsGet = () => wpApi.imageBackgrounds();

/*
 * fake api calls, just for coherence with the rest of the filters.
 * Also, we are ready for wp api integration if it´s eventually needed.
 */
export const imagePersonsGet = () => [
  {
    id: 1,
    slug: "none",
    name: "No"
  },
  {
    id: 3,
    slug: "with_rights",
    name: "Con derechos de imagen"
  },
  {
    id: 2,
    slug: "without_rights",
    name: "Sin derechos de imagen"
  }
];

export const imageOrientationsGet = () => [
  {
    id: 2,
    slug: "horizontal",
    name: "Horizontal"
  },
  {
    id: 1,
    slug: "neutral",
    name: "Neutral"
  },
  {
    id: 3,
    slug: "vertical",
    name: "Vertical"
  }
];

export const imageFinishesGet = () => [
  {
    id: 3,
    slug: "illustration",
    name: "Ilustración"
  },
  {
    id: 2,
    slug: "infographic",
    name: "Infografía"
  },
  {
    id: 1,
    slug: "photo",
    name: "Fotografía"
  }
];

export const imageAnglesGet = () => [
  {
    id: 5,
    slug: "overhead",
    name: "Cenital"
  },
  {
    id: 2,
    slug: "low_angle",
    name: "Contrapicado"
  },
  {
    id: 3,
    slug: "frontal",
    name: "Frontal"
  },
  {
    id: 4,
    slug: "lateral",
    name: "Lateral"
  },
  {
    id: 1,
    slug: "high_angle",
    name: "Picado"
  }
];

export const imagePresentationsGet = () => [
  {
    id: 1,
    slug: "cooked",
    name: "Cocinado"
  },
  {
    id: 3,
    slug: "inside_pack",
    name: "Dentro de pack"
  },
  {
    id: 2,
    slug: "plated",
    name: "Emplatado"
  },
  {
    id: 4,
    slug: "outside_pack",
    name: "Fuera de pack"
  }
];

export const imageLocationsGet = () => [
  {
    id: 2,
    slug: "exterior",
    name: "Exterior"
  },
  {
    id: 1,
    slug: "interior",
    name: "Interior"
  }
];
