import React from "react";
import classNames from "classnames";

import Row from "../Row/Row";
import Column from "../Column/Column";

import "./MainAndAside.css";

const MainAndAside = ({ children, className, ...rest }) => (
  <Row className={classNames("main-and-aside", className)} {...rest}>
    <Column className="main-and-aside__main">{children[0]}</Column>
    <Column className="main-and-aside__aside">{children[1]}</Column>
  </Row>
);

export default MainAndAside;
