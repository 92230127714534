import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 350 350" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fillRule="evenodd">
      <path
        d="M10.537 1.336c0 1.193-.154 1.386-1.442 1.811-2.016.665-5.021 3.596-5.812 5.67-.597 1.562-.778 1.72-1.97 1.72H0v379.707h5.07l.099-189.366c.094-179.253.136-189.428.78-190.537.374-.643.852-1.653 1.063-2.243.211-.591.61-1.074.887-1.074.276 0 1.203-.395 2.058-.878l1.555-.878h377.366l1.366.877c.751.482 1.632.877 1.957.878.56.001 1.471 1.645 2.598 4.684.412 1.113.507 36.379.512 189.952l.006 188.585H400V10.537h-1.14c-.956 0-1.213-.22-1.587-1.353-.675-2.043-3.583-5.052-5.646-5.839-1.626-.622-1.773-.788-1.773-2.011V0H10.537v1.336"
        fill="#747cac"
      />
      <path
        d="M11.707 5.451c-.536.172-1.502.595-2.146.941-.644.345-1.395.629-1.668.63-.274.001-.67.485-.881 1.076-.211.59-.689 1.6-1.063 2.243-.644 1.109-.686 11.284-.78 190.537L5.07 390.244h5.076V12.428l1.141-1.141 1.141-1.141h375.468l1.174 1.229 1.174 1.229v377.64h5.073l-.006-188.585c-.005-153.573-.1-188.839-.512-189.952-1.127-3.039-2.038-4.683-2.598-4.684-.325-.001-1.206-.396-1.957-.878l-1.366-.877-188.098-.065c-103.453-.035-188.536.076-189.073.248"
        fill="#3c4c8c"
      />
      <path
        d="M11.22 11.311l-1.074 1.127v377.806h380.098V12.604l-1.174-1.229-1.174-1.229-187.802.019-187.801.019-1.073 1.127"
        fill="#242c7c"
      />
      <path
        d="M0 5.268v5.269h1.313c1.192 0 1.373-.158 1.97-1.72.791-2.074 3.796-5.005 5.812-5.67 1.288-.425 1.442-.618 1.442-1.811V0H0v5.268m389.854-3.934c0 1.223.147 1.389 1.773 2.011 2.063.787 4.971 3.796 5.646 5.839.374 1.133.631 1.353 1.587 1.353H400V0h-10.146v1.334"
        fill="#848cb4"
      />
    </g>
  </svg>
);
