import React from "react";

import Button from "./../../../../atoms/Button/Button";
import Square from "./../../../../svg/Square";
import Typography, { VARIANT } from "./../../../../atoms/Typography/Typography";
import Download from "./../../../../svg/Download";
import cmsBaseUrl from "./../../../../util/getCmsBaseUrl";

import "./ImageFile.css";
import { PERSON } from "../../../../../config/Constants";

const defaultImage = "/images/aldi_new_logo.png";

const ImageFile = ({ item, type }) => (
  <div className="image-file">
    <div className="image-file__picture">
      <img alt={item.slug} src={item.thumbnail || defaultImage} />
    </div>
    <div className="image-file__info">
      {item.title && (
        <div className="image-file__cell">
          <Typography className="image-file__info-title" tag="div" variant={VARIANT.H4}>
            {item.title}
          </Typography>
        </div>
      )}
      {type === "product" && item.filename && (
        <Typography className="image-file__info-title">{item.filename}</Typography>
      )}
      <div className="image-file__info-columns">
        <div className="image-file__info-columns-first">
          {item.type && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Tipo
              </Typography>
              <Typography tag="div" uppercase>
                {item.type}
              </Typography>
            </div>
          )}
          {type === "product" && item.measures && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Medidas
              </Typography>
              <Typography tag="div">{item.measures}</Typography>
            </div>
          )}
          {item.size && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Peso
              </Typography>
              <Typography tag="div">{item.size}</Typography>
            </div>
          )}
          {item.filename && item.filename.includes(".") && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Archivo
              </Typography>
              <Typography tag="div">
                {item.filename
                  .split(".")
                  .slice(-1)[0]
                  .toUpperCase()}
              </Typography>
            </div>
          )}
          {type === "product" && item.orientation && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Orientación
              </Typography>
              <Typography tag="div">{item.orientation}</Typography>
            </div>
          )}
          {type === "product" && item.finish && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Acabado
              </Typography>
              <Typography tag="div">{item.finish}</Typography>
            </div>
          )}
          {type === "product" && item.location && (
            <div className="image-file__cell">
              <Square className="image-file__square" />
              <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                Ubicación
              </Typography>
              <Typography tag="div">{item.location}</Typography>
            </div>
          )}
        </div>
        {type === "product" && (
          <div className="image-file__info-columns-second">
            {item.angle && (
              <div className="image-file__cell">
                <Square className="image-file__square" />
                <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                  Ángulo
                </Typography>
                <Typography tag="div">{item.angle}</Typography>
              </div>
            )}
            {item.background && (
              <div className="image-file__cell">
                <Square className="image-file__square" />
                <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                  Fondo
                </Typography>
                <Typography tag="div">{item.background}</Typography>
              </div>
            )}
            {item.brand && (
              <div className="image-file__cell">
                <Square className="image-file__square" />
                <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                  Marca
                </Typography>
                <Typography tag="div">{item.brand}</Typography>
              </div>
            )}
            {item.presentation && (
              <div className="image-file__cell">
                <Square className="image-file__square" />
                <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                  Presentación
                </Typography>
                <Typography tag="div">{item.presentation}</Typography>
              </div>
            )}
            {item.person && (
              <div className="image-file__cell">
                <Square className="image-file__square" />
                <Typography className="image-file__cell-title" variant={VARIANT.H5}>
                  Personas físicas
                </Typography>
                <Typography tag="div">
                  {item.person}
                  {item.person === PERSON.with_rights && ` hasta ${item.effectiveDate} para: ${item.support}`}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    <div>
      {item.description && (
        <div className="image-file__cell">
          <Square className="image-file__square" />
          <Typography className="image-file__cell-title" variant={VARIANT.H5}>
            Descripción
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      )}
    </div>
    <div className="image-file__actions">
      <a
        className="image-file__link"
        href={`${cmsBaseUrl}/wp-json/wp/v2/media/download/${item.mediaId}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button className="image-file__link-button" secondary>
          <Typography variant={VARIANT.H5}>Descargar</Typography>
          <Download className="image-file__svg" />
        </Button>
      </a>
    </div>
  </div>
);

export default React.memo(ImageFile);
