import React from "react";

import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import AccordionSelect from "../../components/AccordionSelect/AccordionSelect";
import { PRODUCTS_TITLE } from "../../../config/Constants";
import FilterOptions from "../../components/AccordionSelect/FilterOptions/FilterOptions";
import Arrow from "./../../svg/Arrow";
import Button from "./../../atoms/Button/Button";

import "./AccordionFilterComponent.css";

const AccordionFilterComponent = ({
  active,
  applyFilters,
  brands,
  changeFilters,
  downloadTags,
  imageCategories,
  imageTags,
  mainTaxon,
  selectedFilters,
  showOptions,
  orientations,
  persons,
  backgrounds,
  finishes,
  angles,
  presentations,
  locations
}) => (
  <React.Fragment>
    <Typography variant={VARIANT.H5}>FILTRAR POR</Typography>
    {mainTaxon === PRODUCTS_TITLE && (
      <AccordionSelect
        active={active}
        placeholder="Categoría Subcategoría"
        showOptions={() => showOptions("categories")}
        type="categories"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("categories", option)}
          options={imageCategories}
          selected={selectedFilters.categories}
          type="categories"
        />
      </AccordionSelect>
    )}
    {(mainTaxon === PRODUCTS_TITLE ? imageTags : downloadTags) && (
      <AccordionSelect active={active} placeholder="Tags" showOptions={() => showOptions("tags")} type="tags">
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("tags", option)}
          options={mainTaxon === PRODUCTS_TITLE ? imageTags : downloadTags}
          selected={selectedFilters.tags}
          type="tags"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && brands && (
      <AccordionSelect active={active} placeholder="Marcas" showOptions={() => showOptions("brands")} type="brands">
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("brands", option)}
          options={brands}
          selected={selectedFilters.brands}
          type="brands"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && persons && (
      <AccordionSelect active={active} placeholder="Persona" showOptions={() => showOptions("persons")} type="persons">
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("persons", option)}
          options={persons}
          selected={selectedFilters.persons}
          type="persons"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && orientations && (
      <AccordionSelect
        active={active}
        placeholder="Orientación"
        showOptions={() => showOptions("orientations")}
        type="orientations"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("orientations", option)}
          options={orientations}
          selected={selectedFilters.orientations}
          type="orientations"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && backgrounds && (
      <AccordionSelect
        active={active}
        placeholder="Fondo de imagen"
        showOptions={() => showOptions("backgrounds")}
        type="backgrounds"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("backgrounds", option)}
          options={backgrounds}
          selected={selectedFilters.backgrounds}
          type="backgrounds"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && finishes && (
      <AccordionSelect
        active={active}
        placeholder="Acabado"
        showOptions={() => showOptions("finishes")}
        type="finishes"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("finishes", option)}
          options={finishes}
          selected={selectedFilters.finishes}
          type="finishes"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && angles && (
      <AccordionSelect active={active} placeholder="Angulos" showOptions={() => showOptions("angles")} type="angles">
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("angles", option)}
          options={angles}
          selected={selectedFilters.angles}
          type="angles"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && presentations && (
      <AccordionSelect
        active={active}
        placeholder="Presentación"
        showOptions={() => showOptions("presentations")}
        type="presentations"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("presentations", option)}
          options={presentations}
          selected={selectedFilters.presentations}
          type="presentations"
        />
      </AccordionSelect>
    )}
    {mainTaxon === PRODUCTS_TITLE && locations && (
      <AccordionSelect
        active={active}
        placeholder="Localización"
        showOptions={() => showOptions("locations")}
        type="locations"
      >
        <FilterOptions
          active={active}
          changeFilters={option => changeFilters("locations", option)}
          options={locations}
          selected={selectedFilters.locations}
          type="locations"
        />
      </AccordionSelect>
    )}
    <Button onClick={() => applyFilters()}>
      <Typography className="accordion-filter-component__text" secondary variant={VARIANT.H6}>
        FILTRAR
      </Typography>
      <Arrow className="products__svg" />
    </Button>
  </React.Fragment>
);

export default AccordionFilterComponent;
