import React from "react";

import Page from "./../../layouts/Page/Page";
import Column from "./../../layouts/Column/Column";
import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import Row from "./../../layouts/Row/Row";

import "./Homepage.css";

const Homepage = () => (
  <Page>
    <Row className="homepage">
      <Column className="homepage__image">
        <Typography className="homepage__subtitle" tag="h3" variant={VARIANT.H1}>
          Bienvenido!
        </Typography>
        <img alt="homepage-logo" className="homepage__logo" src="/images/login-background.png" />
      </Column>
    </Row>
  </Page>
);

export default Homepage;
