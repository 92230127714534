import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Button.css";

const Button = ({ className, children, secondary, ...rest }) => (
  <button className={classNames("button", { "button--secondary": secondary }, className)} {...rest}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  /** Prop that identifies a secondary 'level' button */
  secondary: PropTypes.bool
};

Button.defaultProps = {
  secondary: false
};

export default Button;
