import { DOWNLOADS_GROUPS } from "./../../../config/Constants";

const transformChildrenCategory = category => ({
  id: category.term_id,
  name: category.name,
  slug: category.slug,
  children: (category.children && category.children.map(transformChildrenCategory)) || []
});

export const transformCategory = category => ({
  id: category.id,
  name: category.name,
  slug: category.slug,
  children: (category.children && category.children.map(transformChildrenCategory)) || [],
  group: DOWNLOADS_GROUPS[category.group],
  category:
    /* eslint-disable indent */
    category.taxonomy === "product_menu" ? "product" : category.taxonomy === "download_category" ? "download" : "tag"
});
/* eslint-enable indent */
