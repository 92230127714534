export const PRODUCTS_TITLE = "Imágenes";
export const PRODUCTS_SLUG = {
  singular: "imagen",
  plural: "imagenes"
};

export const DOWNLOADS_TITLE = {
  historical: "Histórico",
  "logos-and-brands": "Logos y marcas"
};

export const DOWNLOADS_GROUPS = {
  historical: "historicos",
  "logos-and-brands": "logos-y-marcas"
};

export const ORIENTATION = {
  horizontal: "Horizontal",
  vertical: "Vertical",
  neutral: "Neutral"
};

export const FINISH = {
  photo: "Foto",
  infographic: "Infografía",
  illustration: "Ilustración"
};

export const PRESENTATION = {
  cooked: "Cocinado",
  plated: "Emplatado",
  inside_pack: "Envuelto",
  outside_pack: "Fuera de pack"
};

export const ANGLE = {
  low_angle: "Contrapicado",
  high_angle: "Picado",
  frontal: "Frontal",
  lateral: "Lateral",
  overhead: "Cenital"
};

export const PERSON = {
  none: "No hay",
  with_rights: "Con derechos de imagen",
  without_rights: "Sin derechos de imagen"
};

export const SelectOrderOptions = [
  { name: "Orden alfabético (A-Z)", order: "asc", sort: "slug" },
  { name: "Orden alfabético (Z-A)", order: "desc", sort: "slug" },
  { name: "Fecha (mayor a menor)", order: "asc", sort: "date" },
  { name: "Fecha (menor a mayor)", order: "desc", sort: "date" }
];

export const LOCATION = {
  inside: "Interior",
  outside: "Exterior"
};
