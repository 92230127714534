import React from "react";

/* eslint-disable max-len, no-unused-vars */
export default ({ className, ...rest }) => (
  <svg className={className} viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" stroke="#232E7C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M15.9277073 8.46341463C15.9277073 4.34185366 12.5854146 1 8.46341463 1 4.34229268 1 1 4.34185366 1 8.46341463s3.34229268 7.46341467 7.46341463 7.46341467c4.12199997 0 7.46429267-3.3418537 7.46429267-7.46341467zM13.7908878 13.7900098l5.2090244 4.770878" />
    </g>
  </svg>
);
