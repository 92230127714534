import React from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import { formatRoute } from "react-router-named-routes";

import Loadable from "./../../behaviours/Loadable";
import Page from "./../../layouts/Page/Page";
import Row from "./../../layouts/Row/Row";
import Grid from "./../../layouts/Grid/Grid";
import ProductPreview from "./../../components/ProductPreview/ProductPreview";
import PageHeader from "./../../components/PageHeader/PageHeader";
import Paginator from "./../../components/Paginator/Paginator";
import { DOWNLOADS_TITLE, PRODUCTS_TITLE } from "./../../../config/Constants";
import { BRANDING_SHOW, HISTORIC_SHOW, IMAGE_SHOW, SEARCH_RESULTS } from "./../../../routing";
import Typography, { VARIANT } from "./../../atoms/Typography/Typography";

import "./Search.css";

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changePage = this.changePage.bind(this);
  }

  changePage(selected) {
    const { match } = this.props;
    this.props.history.push(formatRoute(SEARCH_RESULTS, { page: selected, searchTerm: match.params.searchTerm }));
  }

  render() {
    const { products, paging, match } = this.props;
    const currentPage = get(match, "params.page") || 1;
    const totalPages = get(paging, "totalPages") || 0;
    const totalItems = get(paging, "total") || 0;
    return (
      <Page doNotTrack={!products} title="Search">
        <Loadable
          isLoaded={Boolean(products)}
          render={() => (
            <React.Fragment>
              <PageHeader subtitle={`${match.params.searchTerm}`} title={`${totalItems} Resultado(s) para`} />
              {products.length > 0 ? (
                <React.Fragment>
                  <Grid
                    className="search__grid"
                    columnsPerBreakpoint={{ S: 1, SM: 2, XL: 4 }}
                    itemClassName="search__grid-item"
                  >
                    {products.map((product, index) => (
                      <ProductPreview
                        key={index}
                        product={product}
                        to={
                          /* eslint-disable indent */
                          product.mainTaxon === PRODUCTS_TITLE
                            ? IMAGE_SHOW
                            : product.mainTaxon === DOWNLOADS_TITLE.historical
                            ? HISTORIC_SHOW
                            : BRANDING_SHOW
                        }
                        /* eslint-enable indent */
                      />
                    ))}
                  </Grid>
                  <Row className="search__pagination">
                    <Paginator
                      changePage={newPage => this.changePage(newPage)}
                      currentPage={parseInt(currentPage, 10)}
                      totalPages={parseInt(totalPages, 10)}
                    />
                  </Row>
                </React.Fragment>
              ) : (
                <div className="search__no-results">
                  <Typography className="search__no-results-text" variant={VARIANT.H5}>
                    Ningún resultado coincide con la búsqueda
                  </Typography>
                </div>
              )}
            </React.Fragment>
          )}
        />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  products: get(state, "api.search.search"),
  paging: get(state, "api.search.paging")
});
export default connect(mapStateToProps)(React.memo(Search));
