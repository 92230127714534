import React from "react";
import { Provider } from "react-redux";
import { matchRoutes, renderRoutes } from "react-router-config";
import { StaticRouter, withRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import queryString from "query-string";

import breakpoints from "./ui/_config/breakpoints";
import isSSR from "./ui/_config/isSSR";
import ResizeProvider from "./_packages/resize/ui/ResizeProvider";

const dispatchRouteActions = ({ pathname, search }, routeCollection, dispatch) =>
  matchRoutes(routeCollection, pathname).forEach(({ route, match }) =>
    "loadData" in route ? dispatch(route.loadData(match, { search: queryString.parse(search) })) : null
  );

const RouteDataLoader = withRouter(
  class extends React.Component {
    componentDidMount() {
      if (process.env.NODE_ENV === "development") {
        dispatchRouteActions(this.props.location, this.props.routes, this.props.dispatch);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.location !== this.props.location) {
        dispatchRouteActions(nextProps.location, this.props.routes, this.props.dispatch);
      }
    }

    render() {
      return this.props.children;
    }
  }
);

const RouterProvider = isSSR() ? StaticRouter : ConnectedRouter;

export default ({ routes, ssrWindowWidth, store, ...rest }) => (
  <Provider store={store}>
    <ResizeProvider breakpoints={breakpoints} defaultWindowWidth={ssrWindowWidth}>
      <RouterProvider routes={routes} {...rest}>
        <RouteDataLoader dispatch={store.dispatch} routes={routes}>
          {renderRoutes(routes)}
        </RouteDataLoader>
      </RouterProvider>
    </ResizeProvider>
  </Provider>
);
