import React from "react";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";

import { ERROR_CODE_NOT_FOUND } from "./../../../store/Error/ErrorReducer";

import NotFound from "./../../templates/NotFound/NotFound";

const Error = ({ error, route }) => {
  if (error === ERROR_CODE_NOT_FOUND) {
    return <NotFound />;
  }

  return renderRoutes(route.routes);
};

export default connect(state => ({ error: state.error.error }))(Error);
