const BREAKPOINTS = {
  S: 0,
  SM: 480,
  M: 768,
  L: 1024,
  XL: 1280,
  XXL: 1440,
  XXXL: 1680,
  XXXXL: 1920
};

const valuePerBreakpointForConfig = valuePerBreakpointConfig => {
  const valuePerBreakpointConfigKeys = Object.keys(valuePerBreakpointConfig)
    .sort((keyA, keyB) => (BREAKPOINTS[keyA] > BREAKPOINTS[keyB] ? 1 : -1))
    .reverse();

  return width => valuePerBreakpointConfig[valuePerBreakpointConfigKeys.find(key => BREAKPOINTS[key] <= width)];
};

export { valuePerBreakpointForConfig };

export default BREAKPOINTS;
