import React from "react";
import classNames from "classnames";

import Typography, { VARIANT } from "../../../atoms/Typography/Typography";

import "./OrderOptions.css";

const OrderOptions = ({ active, changeOption, options, type }) => (
  <div className={classNames("order-options", { "order-options--active": type === active })}>
    {options.map((option, index) => (
      <div className="order-options__option" key={index} onClick={() => changeOption(option)}>
        <Typography variant={VARIANT.H5}>{option.name}</Typography>
      </div>
    ))}
  </div>
);

export default OrderOptions;
