import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

import ArrowDown from "./../../svg/ArrowDown";

import "./Paginator.css";

const Paginator = ({ changePage, currentPage, totalPages }) => (
  <ReactPaginate
    activeClassName="paginator__item--active"
    containerClassName="paginator"
    disableInitialCallback={true}
    initialPage={currentPage - 1}
    marginPagesDisplayed={1}
    nextClassName="paginator__next"
    nextLabel={<ArrowDown className="paginator__pyke--next" />}
    onPageChange={paging => changePage(parseInt(paging.selected, 10) + 1)}
    pageClassName="paginator__item"
    pageCount={totalPages}
    pageRangeDisplayed={2}
    previousClassName="paginator__previous"
    previousLabel={<ArrowDown className="paginator__pyke--previous" />}
  />
);

Paginator.propTypes = {
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default React.memo(Paginator);
