import WPAPI from "wpapi";

const wpApi = new WPAPI({
  endpoint: `${process.env.REACT_APP_CMS_BASE_URL}/wp-json`
});

wpApi.login = wpApi.registerRoute("jwt-auth/v1", "/token/");

// Product/Images related routes
wpApi.products = wpApi.registerRoute("wp/v2", "/product/");
wpApi.productMenu = wpApi.registerRoute("wp/v2", "/product_menu/");
wpApi.productCategories = wpApi.registerRoute("wp/v2", "/product_category/");
wpApi.productTags = wpApi.registerRoute("wp/v2", "/product_tag/");

// Downloads related routes
wpApi.downloads = wpApi.registerRoute("wp/v2", "/download/");
wpApi.downloadMenu = wpApi.registerRoute("wp/v2", "/download_category/");
wpApi.downloadTags = wpApi.registerRoute("wp/v2", "/download_tag/");

// Search related routes
wpApi.search = wpApi.registerRoute("wp/v2", "/search/");
wpApi.brands = wpApi.registerRoute("wp/v2", "/brands/");
wpApi.imageBackgrounds = wpApi.registerRoute("wp/v2", "/image_background/");
wpApi.imageSearch = wpApi.registerRoute("wp/v2", "/image_search/");

export default wpApi;
