import React from "react";
import { connect } from "react-redux";

import Loadable from "./../../behaviours/Loadable";
import Page from "./../../layouts/Page/Page";
import ProductHeader from "./ProductHeader/ProductHeader.js";
import ProductDownloads from "./ProductDownloads/ProductDownloads";
import PageHeader from "./../../components/PageHeader/PageHeader";

const Product = ({ product }) => (
  <Page doNotTrack={!product} title={product ? product.title : ""}>
    <Loadable
      isLoaded={Boolean(product)}
      render={() => (
        <React.Fragment>
          <PageHeader
            subtitle={product && product.title}
            title={product.mainTaxon && product.subTaxon ? `${product.mainTaxon.name} ${product.subTaxon.name}` : ""}
          />
          <ProductHeader product={product} />
          <ProductDownloads product={product} />
        </React.Fragment>
      )}
    />
  </Page>
);

const mapStateToProps = state => ({
  product: state.api.product
});

export default connect(mapStateToProps)(Product);
