import React from "react";
import classNames from "classnames";
import { formatRoute } from "react-router-named-routes";
import { Link } from "react-router-dom";

import { HOMEPAGE } from "../../../routing";
import Typography, { VARIANT } from "../../atoms/Typography/Typography";
import MenuItem from "./../../components/MenuItem/MenuItem";
import Heart from "./../../svg/Heart";
import Historic from "./../../svg/Historic";
import Images from "./../../svg/Images";

import "./SideMenu.css";

class SideMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      slug: null
    };
    this.toggleExpandable = this.toggleExpandable.bind(this);
  }

  toggleExpandable(name, slug) {
    this.setState({ name: name, slug: slug });
  }

  render() {
    const { name, slug } = this.state;
    return (
      <nav className="side-menu">
        <div className="side-menu__item">
          <Link className="side-menu__logo" to={formatRoute(HOMEPAGE)}>
            <img alt="aldi-logo" src="/images/logo.png" />
          </Link>
        </div>
        <div
          className={classNames("side-menu__item", { "side-menu__item--active": name === "Imágenes" })}
          onClick={() => this.toggleExpandable("Imágenes", "imagenes")}
        >
          <Images />
          <Typography
            className={classNames("side-menu__text", { "side-menu__text--active": name === "Imágenes" })}
            uppercase
            variant={VARIANT.MENU}
          >
            Imágenes
          </Typography>
        </div>
        <div
          className={classNames("side-menu__item", { "side-menu__item--active": name === "Histórico" })}
          onClick={() => this.toggleExpandable("Histórico", "historicos")}
        >
          <Historic />
          <Typography
            className={classNames("side-menu__text", { "side-menu__text--active": name === "Histórico" })}
            uppercase
            variant={VARIANT.MENU}
          >
            Histórico
          </Typography>
        </div>
        <div
          className={classNames("side-menu__item", { "side-menu__item--active": name === "Logos y marcas" })}
          onClick={() => this.toggleExpandable("Logos y marcas", "logos-y-marcas")}
        >
          <Heart />
          <Typography
            className={classNames("side-menu__text", { "side-menu__text--active": name === "Logos y marcas" })}
            uppercase
            variant={VARIANT.MENU}
          >
            Logos y Marcas
          </Typography>
        </div>
        {name && <MenuItem closeMenu={this.toggleExpandable} name={name} slug={slug} />}
      </nav>
    );
  }
}

export default React.memo(SideMenu);
